.container {
  width: 100%;
  max-width: 1920px;
  box-sizing: border-box;

  &.full {
    padding: 0 112px;
    @media (max-width: 1023px) {
      padding: 0 2rem;
    }
    @media (max-width: 479px) {
      padding: 0 1rem;
    }
  }

  &.xl {
    max-width: 1720px;
  }
  &.lg {
    max-width: 1480px;
  }
  &.md {
    max-width: 1280px;
  }
  &.sm {
    max-width: 1024px;
  }
  &.xs {
    max-width: 768px;
  }
}

.grid-container {
  display: grid;
  grid-gap: 2rem;

  &.col-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  &.col-2 {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 1023px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.col-3 {
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.col-4 {
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 1023px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 479px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}

.heading {
  font-weight: 700;

  &.h-7 {
    font-size: 7rem;

    @media (max-width: 1376px) {
      font-size: 6rem;
    }

    @media (max-width: 1023px) {
      font-size: 5rem;
    }

    @media (max-width: 767px) {
      font-size: 4rem;
    }
    @media (max-width: 479px) {
      font-size: 3rem;
    }
  }

  &.h-6 {
    font-size: 6rem;

    @media (max-width: 1376px) {
      font-size: 5rem;
    }

    @media (max-width: 1023px) {
      font-size: 4rem;
    }

    @media (max-width: 767px) {
      font-size: 3rem;
    }
  }

  &.h-5 {
    font-size: 5rem;

    @media (max-width: 1376px) {
      font-size: 4rem;
    }

    @media (max-width: 1023px) {
      font-size: 3rem;
    }

    @media (max-width: 767px) {
      font-size: 2.5rem;
    }
  }

  &.h-4 {
    font-size: 4rem;

    @media (max-width: 1376px) {
      font-size: 3.5rem;
    }

    @media (max-width: 1023px) {
      font-size: 3rem;
    }

    @media (max-width: 767px) {
      font-size: 2.5rem;
    }
  }

  &.h-3 {
    font-size: 3rem;

    @media (max-width: 1376px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1023px) {
      font-size: 2.5rem;
    }

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &.h-25 {
    font-size: 2.5rem;

    @media (max-width: 1376px) {
      font-size: 2.3rem;
    }

    @media (max-width: 1023px) {
      font-size: 2.1rem;
    }

    @media (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  &.h-2 {
    font-size: 2rem;

    @media (max-width: 1376px) {
      font-size: 1.8rem;
    }

    @media (max-width: 1023px) {
      font-size: 1.6rem;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  &.h-15 {
    font-size: 1.5rem;

    @media (max-width: 1376px) {
      font-size: 1.4rem;
    }

    @media (max-width: 1023px) {
      font-size: 1.3rem;
    }

    @media (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &.h-12 {
    font-size: 1.2rem;
  }

  &.h-1 {
    font-size: 1rem;
  }
}
