@font-face {
  font-family: "Flama";
  src: local("Flama-Light"),
    url("../../fonts/Flama-Light.otf") format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "Flama";
  src: local("Flama-Basic"),
    url("../../fonts/Flama-Basic.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Flama";
  src: local("Flama-medium"),
    url("../../fonts/Flama-Medium.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Flama";
  src: local("Flama-Bold"), url("../../fonts/Flama-Bold.otf") format("opentype");
  font-weight: 700;
}

@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,900;1,300;1,900&display=swap");
@import "variables.scss";
@import "mixins.scss";
@import "header.scss";
@import "footer.scss";
@import "numbers.scss";
@import "home.scss";
@import "projects.scss";
@import "modal.scss";

@import "buttons.scss";
@import "layout.scss";
@import "colors.scss";
@import "common.scss";
@import "form.scss";

body {
  margin: 0;
  font-family: $primary-font;
  font-weight: 200;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 100%;
}

.App {
  display: flex;
  flex-flow: column;
  overflow-x: hidden;

  @include transition();
}

.title-font {
  font-family: $title-font;
}

.font-weight-regular {
  font-weight: 300;
}

.icon {
  width: 24px;
  height: 24px;

  &.lg {
    width: 50px;
    height: 50px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.visit {
  color: inherit;

  a {
    display: flex;
    flex-flow: column;
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-size: 0.9rem;
    white-space: nowrap;

    @media (max-width: 1023px) {
      font-size: 0.7rem;
    }
  }

  .button {
    width: 70px;
    height: 70px;
    border: 1px solid;

    margin-bottom: 0.5rem;

    @media (max-width: 1023px) {
      width: 50px;
      height: 50px;
    }
  }

  &.white {
    .button {
      border-color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }
  &.black {
    .button {
      border-color: $color-black;

      svg {
        fill: $color-black;
      }
    }
  }
}

/* SLIDERS */

.slider {
  overflow: visible !important;

  .swiper-slide {
    height: auto;
  }

  &.slides-5 {
    .swiper-slide {
      width: calc(20% - 2rem) !important;

      @media (max-width: 1280px) {
        width: calc(30% - 2rem) !important;
      }
      @media (max-width: 1024px) {
        width: calc(40% - 2rem) !important;
      }
      @media (max-width: 768px) {
        width: calc(60% - 2rem) !important;
      }
      @media (max-width: 480px) {
        width: calc(80% - 2rem) !important;
      }
    }
  }

  &.slides-2 {
    .swiper-slide {
      width: calc(80% - 2rem) !important;
    }
  }
}

.slider-stragog {
  overflow: visible !important;
  .swiper-slide {
    padding: 0 1rem;
    box-sizing: border-box;

    &.vertical {
      width: 21.3vw !important;

      @media (max-width: 1023px) {
        width: 37.7vw !important;
      }
    }

    &.horizontal {
      width: 45vw !important;

      @media (max-width: 1023px) {
        width: 80vw !important;
      }
    }
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

.slider-nucleo,
.slider-pangea {
  overflow: visible !important;

  .swiper-slide {
    padding: 0 1rem;
    box-sizing: border-box;

    &.vertical {
      width: 24.6vw !important;

      @media (max-width: 1023px) {
        width: 43.7vw !important;
      }
    }

    &.horizontal {
      width: 45vw !important;

      @media (max-width: 1023px) {
        width: 80vw !important;
      }
    }
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

.text-align-center {
  text-align: center;
}
