.Header {
  top: 0;
  z-index: 990;
  width: 100%;
  height: 92px;
  display: flex;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: center;

  position: absolute;

  &.menuOpen {
    position: fixed !important;
    top: -92px !important;
    transform: translate(0px, 92px) !important;
  }

  &.scrolled {
    @include transition(0.15s, transform);
    position: fixed;
    top: -92px;

    &.visible {
      transform: translate(0px, 92px);
    }
  }

  .brand {
    height: 60px;
    margin-right: auto;

    @media (max-width: 767px) {
      height: 50px;
    }

    svg {
      height: 100%;
      width: auto;
    }
  }

  .brand-current {
    height: 60px;
    margin-left: auto;
    @media (max-width: 767px) {
      height: 50px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .menu-toggle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 60px;
    height: 60px;
  }
}

.hamburger {
  width: 60px;
  height: 60px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  @include transition();

  .line {
    width: 40px;
    height: 2px;
    background: $color-white;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    @include transition();

    &.bread-top {
      top: 35%;
    }
    &.meat {
      top: 50%;
    }
    &.bread-bottom {
      top: 65%;
    }
  }

  .dish {
    position: absolute;
    width: 100%;
    height: 100%;
    background: $color-white;
    border-radius: 50%;
    transform: scale(0.5);
    opacity: 0;

    &:hover {
      transform: scale(1);
      opacity: 0.1;
    }

    @include transition();
  }

  &.open {
    &:hover {
      transform: rotate(180deg);
    }

    .line {
      width: 30px;

      &.bread-top {
        top: 50%;
        transform: rotate(45deg);
      }
      &.meat {
        width: 0;
      }
      &.bread-bottom {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}

.Navigation {
  position: fixed;
  z-index: 989;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.open {
    visibility: visible;
  }

  .Navigation-circle-white {
    background-color: $color-white;
    border-radius: 50%;
    flex: 0 0 auto;
    position: absolute;
  }
  .Navigation-circle-black {
    background-color: $color-black;
    border-radius: 50%;
    flex: 0 0 auto;
    position: absolute;
  }

  .Navigation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    color: $color-white;
    box-sizing: border-box;
    display: flex;
    overflow: auto;

    @media (max-width: 1279px) {
      padding: 0 1rem;
    }

    .Navigation-container__inner {
      margin: auto;
      width: 100%;
      max-width: 1280px;
      padding-top: 100px;
      opacity: 0;
    }

    .Navigation-sections {
      width: 100%;
      display: flex;
      justify-content: center;

      @media (max-width: 768px) {
        flex-wrap: wrap;
      }

      .Navigation-sections__item {
        width: 115px;
        height: auto;
        border-radius: 50%;
        opacity: 0;
        margin: 0 1rem;
        cursor: pointer;
        aspect-ratio: 1;

        @media (max-width: 1280px) {
          width: 100px;
          margin: 0 0.5rem;
        }
        @media (max-width: 768px) {
          width: 80px;
          margin: 0.5rem;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .Navigation-content {
      display: flex;
      margin-top: 2rem;

      @media (max-width: 1023px) {
        flex-flow: column;
        margin-top: 0;
        align-items: center;
        text-align: center;
        max-width: 400px;
        margin: auto;
      }

      .Navigation-content__block {
        flex: 0 0 33.33%;
        font-size: 1.2rem;

        @media (max-width: 1023px) {
          flex: 0 0 100%;
          margin-bottom: 1.5rem;
          font-size: 1.1rem;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        h2 {
          margin: 0 0 2rem 0;
          @media (max-width: 1280px) {
            margin: 0 0 0.5rem 0;
          }
        }

        a {
          color: $color-white;
          text-decoration: none;
        }

        .item {
          margin-bottom: 1rem;
          @media (max-width: 1280px) {
            margin: 0 0 0.5rem 0;
          }
        }

        .social {
          display: flex;
          a {
            display: flex;
            color: $color-white;
            text-decoration: none;
            font-size: 1.2rem;
            align-items: center;
            border-radius: 0.5rem;
            margin-right: 1rem;

            @include transition();

            &:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
            @media (max-width: 767px) {
              margin: 0 1rem;
            }
          }
        }
      }
    }
  }
}
