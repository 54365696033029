.Home {
  color: $color-white;
}

.Intro {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    min-height: 70vh;
    margin: 120px 0;
  }

  .container {
    text-align: center;
    max-width: 900px;

    h1 {
      font-size: 5rem;
      margin: 0;
      line-height: 1.1;

      @media (max-width: 1023px) {
        font-size: 4rem;
      }
      @media (max-width: 767px) {
        font-size: 3rem;
      }
    }

    p {
      margin: 2rem auto 0;
      font-size: 1.7rem;
      max-width: 710px;
      line-height: 1.3;

      @media (max-width: 767px) {
        font-size: 1.5rem;
      }
    }
  }
}

.Press {
  .Press-slide {
    margin-right: 2rem !important;
    width: calc(25% - 2rem) !important;

    @media (max-width: 1399px) {
      width: calc(30% - 2rem) !important;
    }
    @media (max-width: 1279px) {
      width: calc(33% - 2rem) !important;
    }
    @media (max-width: 979px) {
      width: calc(40% - 2rem) !important;
    }
    @media (max-width: 767px) {
      width: calc(55% - 2rem) !important;
    }
    @media (max-width: 579px) {
      width: calc(70% - 2rem) !important;
    }
    @media (max-width: 479px) {
      width: calc(75% - 2rem) !important;
    }

    &:last-of-type {
      margin-right: 0 !important;
    }

    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-flow: column;

    h3 {
      margin: 1rem 0;
    }

    p {
      font-size: 1.2rem;
      margin: auto 0 1rem 0;
    }
  }
}

.Press-detail {
  color: $color-black;

  h3 {
    margin: 1rem 0;
  }
  p {
    font-size: 1.2rem;
    margin: auto 0 1rem 0;
  }
}

.Phrases {
  margin-bottom: 20vh;

  .Phrase-slide {
    margin-right: 300px !important;
    width: calc(75% - 300px) !important;

    @media (max-width: 1279px) {
      margin-right: 200px !important;
      width: calc(85% - 200px) !important;
    }
    @media (max-width: 1023px) {
      margin-right: 150px !important;
      width: calc(90% - 150px) !important;
    }
    @media (max-width: 767px) {
      margin-right: 100px !important;
      width: calc(90% - 100px) !important;
    }
    @media (max-width: 579px) {
      margin-right: 50px !important;
      width: calc(100% - 50px) !important;
    }

    @include transition();

    &.swiper-slide-active {
      p {
        opacity: 1;
      }
    }

    p {
      margin: 2rem 0 0 0;
      font-size: 1.5rem;
      opacity: 0.5;

      @media (max-width: 579px) {
        font-size: 1.2rem;
      }
    }
  }
}

.Sections {
  padding: 20vh 0;
  overflow: hidden;

  .Section {
    min-height: 50vw;
    position: relative;
    padding-top: 200px;

    &.DISS {
      color: $color-black;
    }

    .section-content {
      position: relative;
      box-sizing: border-box;
      margin: auto;
      z-index: 1;
      width: 100%;
      padding: 0 1rem;
      transform: translate(0, -100px);
      display: flex;
      flex-flow: column;
      align-items: center;
      @include transition(opacity, 0.25s);

      .image {
        width: 100%;
        max-width: 600px;
        border-radius: 1rem;
        overflow: hidden;
        transform: rotate(5deg);
        padding: 0 2rem;
        box-sizing: border-box;
        @include transition();

        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
        }
      }

      .meta {
        margin: 3rem 0 0 0;
        text-align: center;
        @include transition();

        h3 {
          margin: 0.5rem 0;
        }

        .desc {
          font-size: 1.5rem;
          margin: 1rem 0 2rem;
        }
      }
    }

    .section-circle {
      position: absolute;
      width: 100vw;
      height: 100vw;
      background: white;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      left: 50%;
      top: 200px;
      transform: translate(-50%, 0);
      @include transition();
    }

    @media (max-width: 900) {
      .background {
        position: absolute;
        bottom: 0;
        height: 50vw;
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      .background {
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 100vw;
      }
    }

    .section-detail {
      position: relative;
      min-height: 800px;
    }
  }
}

.WhatWeDo {
  margin-top: calc((100vw - 224px) / 5 / 2) !important;

  @media (max-width: 1023px) {
    margin-top: calc((100vw - 224px) / 3 / 2) !important;
  }

  .slider {
    display: flex;
    color: $color-white;

    .WhatWeDo-slide {
      width: calc(100% / 3);
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 767px) {
        width: 45%;
      }
      @media (max-width: 579px) {
        width: 60%;
      }

      .proportion {
        width: 100%;
        padding-bottom: 100%;
      }

      &:first-of-type {
        .image {
          width: 90% !important;
        }
      }

      .slide-content {
        position: absolute;

        .image {
          width: 100%;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -120%);

          img {
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
          }
        }

        .product {
          font-weight: 400;
          opacity: 0.75;
          margin: 0.5rem 0;
        }
      }

      .circle {
        position: absolute;
        width: 110%;
        padding-bottom: 110%;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 50%;
      }
    }
  }
}

.talk {
  .talk__item {
    margin: 0 2rem;
    img {
      max-width: 300px;
      width: 100%;
      height: auto;
    }
  }
}
