.button {
    font-family: $primary-font;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: none;
    align-items: center;
    justify-content: center;
    outline: none;
    white-space: nowrap;
    background-color: transparent;
   
    @include transition();
  
    &:focus {
      border: 0;
      outline: none;
    }

    &.circle{

        border-radius: 50%;

        &.sm{
            width: 40px;
            height: 40px
        }
        &.md{
            width: 50px;
            height: 50px
        }
        &.lg{
            width: 60px;
            height: 60px
        }
        &.xl{
            width: 80px;
            height: 80px
        }

    }


}