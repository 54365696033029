.Footer {
  position: relative;
  background-color: $color-black;

  .footer-social {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    @media (max-width: 1023px) {
      border-top: 1px solid $color-white;
    }

    a {
      display: flex;
      color: $color-white;
      text-decoration: none;
      font-size: 1.2rem;
      align-items: center;
      border-radius: 0.5rem;
      margin: 0 1rem;
      padding-right: 1rem;

      @media (max-width: 480px) {
        padding-right: 0;
      }

      @include transition();

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      @media (max-width: 767px) {
        margin: 0 1rem;
      }
      span {
        @media (max-width: 480px) {
          display: none;
        }
      }
    }
  }

  .footer-container {
    background-color: transparent;
    border-top: 1px solid $color-white;
    color: $color-white;
    position: relative;
    text-transform: uppercase;
    font-size: 1rem;
    display: flex;

    @media (min-width: 1024px) {
      padding: 2rem;
      align-items: center;
    }

    @media (max-width: 1023px) {
      flex-flow: column;
    }

    .footer-content {
      width: 100%;
      display: flex;

      @media (max-width: 1023px) {
        padding: 1rem 1rem 2rem 1rem;
        box-sizing: border-box;
      }

      .legal {
        display: flex;
        margin-left: auto;

        span {
          cursor: pointer;
          color: $color-white;
          text-decoration: none;

          margin-left: 1rem;
        }
      }
    }

    .footer-nav {
      display: flex;

      @media (min-width: 1024px) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
      }

      @media (max-width: 1023px) {
        display: flex;
        width: 100%;
        justify-content: center;
        border-bottom: 1px solid $color-white;
        padding: 2rem 0;
      }

      @media (max-width: 767px) {
        flex-wrap: wrap;
      }

      img {
        margin: 0 0.5rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        @media (max-width: 1023px) {
          margin: 0.5rem;
          width: 65px;
          height: 65px;
        }
      }
    }
  }
}

.Organization {
  position: relative;
  min-height: 100vh;
  padding-top: 120px;

  h3 {
    font-weight: 400;
    margin-top: 0.5rem;
    line-height: 1.5;
    opacity: 0.8;
  }
}

.collabora {
  position: relative;
  min-height: 100vh;
  width: 100%;
  max-width: 768px;
  margin: auto;
  display: flex;
  align-items: center;

  .collabora-intro {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  .collabora-form {
    margin-bottom: 10vh;

    .response {
      color: $color-white;
    }
  }
}

.Story {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 120px;

  @media (max-width: 1023px) {
    padding: 10vh 2rem;
  }
  @media (max-width: 1023px) {
    padding: 10vh 2rem;
  }

  h3 {
    font-weight: 300;
    text-align: center;
    margin-bottom: 10vh;
  }

  .slider {
    width: 100%;

    .swiper-container {
      overflow: visible;
    }
  }

  .Story-slide {
    opacity: 0.5;

    margin-right: 300px !important;
    width: calc(75% - 300px) !important;

    @media (max-width: 1279px) {
      margin-right: 200px !important;
      width: calc(85% - 200px) !important;
    }
    @media (max-width: 1023px) {
      margin-right: 150px !important;
      width: calc(90% - 150px) !important;
    }
    @media (max-width: 767px) {
      margin-right: 100px !important;
      width: calc(90% - 100px) !important;
    }
    @media (max-width: 579px) {
      margin-right: 50px !important;
      width: calc(100% - 50px) !important;
    }

    @include transition();

    &.swiper-slide-active {
      opacity: 1;
    }

    h3 {
      text-align: left;
      margin-bottom: 0;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
    }
  }
}

.legal-modal {
  font-size: 1.2rem;

  a {
    color: $color-black;
  }
}
