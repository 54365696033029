.Projects {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fce018;
  z-index: 99;
  overflow-y: scroll;
}

.project {
  padding-top: 10vh;

  .Sections {
    padding: 0;
  }
}

.project-desc {
  display: flex;
  @media (max-width: 1023px) {
    flex-flow: column;
  }

  .desc {
    font-weight: 200;
    line-height: 1.1;
    width: 50%;
    font-size: 2rem;

    @media (max-width: 768px) {
      width: 100%;
    }

    span {
      font-size: 1rem;
      display: flex;
      margin-top: 1rem;
    }
  }
  .visit {
    flex: 0 0 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    .visitSite {
      width: 150px;
      height: 150px;
    }

    @media (max-width: 1279px) {
      flex: 0 0 300px;
    }
    @media (max-width: 1023px) {
      margin-top: 10vh;
      flex: 0 0 100%;
    }
  }
}

.project-outro {
  padding: 20vh 0;
  font-style: italic;
  text-align: center;
  font-weight: 300 !important;

  @media (max-width: 767px) {
    br {
      display: none;
    }
  }
}

.project-slider {
  height: 50vh;
  display: flex;
  transform: rotate(-3deg);
  padding: 2rem 0;

  &[direction-left] {
    justify-content: right;
  }
  &[direction-right] {
    justify-content: left;
  }

  .project-slider-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    .project-slide {
      margin-left: 2rem;
      background-color: $color-black;
      height: 100%;

      img {
        overflow: hidden;
        border-radius: 1rem;
        height: 100%;
        width: auto;
      }
    }
  }
}

.project-side-desc {
  max-width: 450px;
  margin-left: auto;
  padding: 10vh 0;
  font-size: 1.2rem;

  @media (max-width: 1023px) {
    margin: auto;
    text-align: center;
  }
}

.project-video {
  width: 100%;
  margin-top: 20vh;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-flow: column;
    align-items: flex-end;
  }

  &.alternate {
    flex-flow: row-reverse;

    @media (max-width: 767px) {
      flex-flow: column;
    }

    .video {
      padding-left: 112px;
      padding-right: 0;

      @media (max-width: 767px) {
        padding: 0;
        margin-bottom: 2rem;
      }
    }
  }

  .video {
    padding-right: 112px;

    @media (max-width: 767px) {
      padding: 0;
      margin-bottom: 2rem;
    }

    video {
      width: 100%;
      height: auto;
      display: block;
      outline: none;
    }
  }
}

.bookstore {
  display: flex;
  margin-top: 10vh;

  @media (max-width: 1023px) {
    flex-flow: column;
  }

  .desc {
    flex: 0 0 50%;
    display: flex;

    @media (max-width: 1023px) {
      flex: 0 0 100%;
      max-width: 768px;
      margin: 2rem auto;
    }

    p {
      flex: 0 0 300px;
      margin: auto;
      font-size: 1.2rem;

      @media (max-width: 1023px) {
        flex: 0 0 100%;
      }
    }
  }

  .images {
    display: flex;
    align-items: flex-start;
    width: 100%;

    @media (max-width: 1023px) {
      max-width: 768px;
      margin: auto;
    }

    img {
      width: calc(50% - 1rem);
      height: auto;
      display: block;
      margin-right: 2rem;
      border-radius: 1rem;
      flex: 0 0 auto;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.project-image {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 1023px) {
    margin-top: 10vh;
  }

  @media (max-width: 767px) {
    flex-flow: column-reverse;
  }

  .desc {
    flex: 0 0 200px;
    text-align: right;
    padding-right: 2rem;
    font-size: 1.1rem;

    @media (max-width: 767px) {
      flex: unset;
      margin-top: 5vh;
      width: 50%;
      padding-right: 0;
    }
  }
  .image {
    flex: 0 0 50%;

    @media (max-width: 767px) {
      padding: 0 3rem;
    }

    img {
      width: 100%;
      transform: rotate(-5deg);
      border-radius: 0.5rem;
    }
  }
}

.project-mobile {
  width: 100%;
  display: flex;

  margin-top: 2rem;

  .content {
    display: flex;
    align-items: flex-end;

    @media (max-width: 1023px) {
      flex-flow: column;
      width: 70% !important;
      align-items: flex-start;
    }

    .image {
      flex: 0 0 40%;

      img {
        width: 100%;
      }
    }
    .desc {
      flex: 0 0 375px;
      padding-left: 2rem;
      font-size: 1.2rem;

      @media (max-width: 1023px) {
        flex: unset;
        padding-left: 0;
        margin-top: 1rem;
      }
    }
  }

  .visit {
    margin-bottom: auto;
    margin-top: 10vh;
    margin-right: auto;

    @media (max-width: 1023px) {
      margin: auto 0 auto auto;
    }
  }
}

.gog-grid {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  justify-content: center;

  @media (max-width: 1023px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(3, 1fr);

    div {
      &:nth-child(7) {
        display: none;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.bi-full {
  display: flex;
  justify-content: flex-end;
  margin-top: 20vh;

  video {
    width: 80%;
    height: auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.bi-grid {
  position: relative;
  min-height: 100vh;
  margin: 0 2rem;

  @media (max-width: 1279px) {
    min-height: 80vh;
  }
  @media (max-width: 1023px) {
    min-height: 60vh;
  }
  @media (max-width: 767px) {
    min-height: auto;
  }
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  @media (min-width: 768px) {
    div {
      width: 25%;
      position: absolute;
    }
    .pos-1 {
      left: 0;
      top: 0;
    }

    .pos-2 {
      right: 10%;
      top: 0;
    }
    .pos-3 {
      left: 33%;
      top: 10%;
    }
    .pos-4 {
      left: 10%;
      bottom: 0;
    }
    .pos-5 {
      right: 0;
      bottom: 0;
    }
  }
  @media (max-width: 767px) {
    div {
      width: 100%;

      &:last-of-type {
        display: none;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.bi-desc {
  position: relative;

  .visit {
    @media (min-width: 768px) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }

    @media (max-width: 767px) {
      position: relative;
      margin-top: 2rem;
      display: flex;
    }
  }
}

.gem-grid {
  margin-top: 20vh;
  display: flex;
  align-items: center;

  @media (max-width: 1279px) {
    flex-flow: column;
    align-items: flex-start;
  }

  .title {
    flex: 0 0 auto;
    margin-right: 3rem;
    font-size: 1.2rem;
    opacity: 0.5;

    @media (max-width: 1279px) {
      margin-bottom: 5rem;
    }
  }

  .courses {
    width: 100%;
    display: flex;

    @media (max-width: 767px) {
      flex-flow: wrap;
    }

    .course {
      width: 25%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 767px) {
        width: 50%;
      }

      .proportion {
        width: 100%;
        padding-bottom: 100%;
      }
      .circle {
        position: absolute;
        width: 110%;
        height: 110%;
        border: 1px solid $color-white;
        border-radius: 50%;
      }
      span {
        position: absolute;
        font-size: 2rem;
        text-align: center;
        font-weight: 700;

        @media (max-width: 767px) {
          font-size: 1.5rem;
        }
        @media (max-width: 399px) {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.id-extra {
  background-color: $color-black;
  color: $color-white;
  padding-top: 1px;

  .id-nucleo,
  .id-collana {
    width: 100%;
    position: relative;
    margin-top: 20vw;

    @media (max-width: 767px) {
      margin-top: 3rem;
    }

    .desc {
      width: 50%;
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 10vw 0 10vw 10vw;
      box-sizing: border-box;
      padding-right: 1rem;
      font-weight: 200;

      @media (max-width: 1499px) {
        font-size: 1.3rem;
      }
      @media (max-width: 1399px) {
        font-size: 1.2rem;
        padding: 5vw 0 5vw 5vw;
      }
      @media (max-width: 1023px) {
        font-size: 1.2rem;
        padding: 5rem 0 0 0;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .image {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: flex-start;

      @media (max-width: 767px) {
        position: relative;
        display: flex;
        margin: 3rem 0;
      }

      img {
        width: 30%;
        height: auto;
        border-radius: 1rem;
        margin-left: calc(62% - 70px);
        flex: 0 0 auto;

        @media (max-width: 767px) {
          width: calc(80% - 70px);
          margin-left: 10%;
        }
      }

      .visit {
        margin-left: auto;
        margin-top: -1rem;

        @media (max-width: 767px) {
          margin: auto 0 auto auto;
        }
      }
    }

    .extra {
      width: 50%;
      padding: 0 10vw 0 0;
      margin-left: 50%;
      box-sizing: border-box;

      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        padding: 10vw 0 0 0;
        display: flex;
      }

      img {
        width: 100%;
        height: auto;

        @media (max-width: 767px) {
          max-width: 400px;
          margin: auto;
        }
      }
    }
  }

  .id-nucleo {
    .extra {
      width: 100%;
      margin-left: 0;
      padding: 0 0 0 10vw;

      @media (max-width: 1399px) {
        padding: 0 0 0 5vw;
      }
      @media (max-width: 1023px) {
        padding: 0;
      }
    }
  }
}

.pangea-extra {
  padding-top: 1px;

  .pangea-collana,
  .pangea-teatro {
    width: 100%;
    position: relative;
    margin-top: 20vw;

    @media (max-width: 767px) {
      margin-top: 3rem;
    }

    .title {
      display: flex;
      flex-direction: column;

      span {
        font-size: 1.5rem;
        font-weight: 300;
      }
    }

    .image {
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: flex-start;

      @media (max-width: 767px) {
        position: relative;
        display: flex;
        margin: 3rem 0;
      }

      .visit {
        margin-left: auto;
        margin-top: -1rem;

        @media (max-width: 767px) {
          margin: auto 0 auto auto;
        }
      }
    }

    .desc {
      width: 50%;
      font-size: 1.5rem;
      line-height: 1.5;
      padding: 10vw 0 10vw 10vw;
      box-sizing: border-box;
      padding-right: 1rem;
      font-weight: 200;

      @media (max-width: 1499px) {
        font-size: 1.3rem;
      }
      @media (max-width: 1399px) {
        font-size: 1.2rem;
        padding: 5vw 0 5vw 5vw;
      }
      @media (max-width: 1023px) {
        font-size: 1.2rem;
        padding: 5rem 0 0 0;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .extra {
      width: 50%;
      padding: 0 10vw 0 0;
      margin-left: 50%;
      box-sizing: border-box;

      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        padding: 10vw 0 0 0;
        display: flex;
      }

      img {
        width: 100%;
        height: auto;

        @media (max-width: 767px) {
          max-width: 400px;
          margin: auto;
        }
      }
    }
  }

  .pangea-teatro {
    .extra {
      width: 100%;
      margin-left: 0;
      padding: 0 0 0 10vw;

      @media (max-width: 1399px) {
        padding: 0 0 0 5vw;
      }
      @media (max-width: 1023px) {
        padding: 0;
      }
    }
  }
}

.stragog-extra {
  .extra {
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
      padding: 10vw 0 0 0;
      display: flex;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
