.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-flow: column;
}

.m-0 {
  margin: 0;
}
.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}
.ml-1 {
  margin-left: 1rem;
}

.mr-auto {
  margin-right: auto;
}

.mh-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-5 {
  margin-bottom: 5rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}

.mtb-1 {
  margin: 1rem 0;
}
.mtb-2 {
  margin: 2rem 0;
}
.mtb-3 {
  margin: 3rem 0;
}
.mtb-5 {
  margin: 5rem 0;
}

.p-0 {
  padding: 0;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}

.italic {
  font-style: italic;
}

.line-height-1 {
  line-height: 1;
}

.oy-hidden {
  overflow-y: hidden;
}

.font-weight-thin {
  font-weight: 200;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-medium {
  font-weight: 600;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-black {
  font-weight: 900;
}
