.Home {
  .number {
    color: $color-white;
  }
}

.Numbers {
  min-height: 80vh;
  margin: 20vh 0;
  position: relative;
  padding: 0;

  @media (max-width: 767px) {
    display: flex;
    flex-flow: column;
  }

  &.num-4 {
    min-height: 50vh;

    @media (min-width: 768px) {
      .pos-1 {
        left: 0;
        top: 0;
      }
      .pos-2 {
        left: 60%;
        top: 0;
      }
      .pos-3 {
        left: 30%;
        bottom: 0;
      }
      .pos-4 {
        right: 10%;
        bottom: 0;
      }
    }
  }

  &.num-5 {
    @media (min-width: 768px) {
      .pos-1 {
        left: 0;
        top: 0;
      }
      .pos-2 {
        right: 0;
        top: 0;
      }
      .pos-3 {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .pos-4 {
        left: 0;
        bottom: 0;
      }
      .pos-5 {
        right: 20%;
        bottom: 0;
      }
    }
  }

  &.num-6 {
    @media (min-width: 768px) {
      .pos-1 {
        left: 0;
        top: 0;
      }
      .pos-2 {
        right: 0;
        top: 0;
      }
      .pos-3 {
        right: 50%;
        top: 25%;
      }
      .pos-4 {
        left: 50%;
        bottom: 25%;
      }
      .pos-5 {
        left: 0;
        bottom: 0;
      }
      .pos-6 {
        right: 10%;
        bottom: 0;
      }
    }
  }

  .number {
    display: flex;
    flex-flow: column;
    font-size: 1.2rem;

    @media (min-width: 768px) {
      position: absolute;
    }

    @media (max-width: 767px) {
      position: relative;
      margin: 2rem 0;

      &:nth-child(2n + 2) {
        margin-left: auto;
      }
    }

    .amount {
      font-weight: 600;
      font-size: 6rem;

      @media (max-width: 1023px) {
        font-size: 5rem;
      }
      @media (max-width: 767px) {
        font-size: 4rem;
      }

      @media (min-aspect-ratio: 20/9) {
        font-size: 4rem;
      }

      &.MAGOG {
        color: #ff00ff;
      }
      &.DISS {
        color: $color-black;
      }
      &.GOG {
        color: #ff4f00;
      }
      &.BI {
        color: #606060;
      }
      &.GEM {
        color: #fb5a56;
      }
      &.LP {
        color: #00ffff;
      }
      &.RC {
        color: #58ff79;
      }
    }
  }
}
