@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}


@mixin transition($duration: .25s, $target: all ){
		-webkit-transition: $target $duration ease-out;
    -moz-transition: $target $duration ease-out;
    -o-transition: $target $duration ease-out;
    transition: $target $duration ease-out;
}


@mixin bgRgba($background, $opacity: 1){
		background-color: rgba( $background, $opacity );
}

@mixin cRgba($color, $opacity: 1){
		 color: rgba( $color, $opacity );
}
