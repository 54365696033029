.Modal {
  position: fixed;
  z-index: 992;
  background-color: $color-white;
  width: calc(100% - 2rem);
  max-width: 768px;
  height: auto;
  max-height: calc(100% - 2rem);

  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  box-sizing: border-box;
  box-shadow: 0px 7px 35px -14px rgba(0, 0, 0, 0.5);

  flex-flow: column;

  display: none;

  opacity: 0;

  &.xs {
    max-width: 480px;
  }

  &.open {
    display: flex;
  }

  .Modal-header {
    display: flex;
    align-items: center;
    padding: 2rem 2rem 0 2rem;
  }

  .Modal-content {
    padding: 2rem;
  }
}

.Backdrop {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include bgRgba($color-black, 0.5);
  @include transition(0.5);

  opacity: 0;

  &.open {
    opacity: 1;
  }
}
