.color-white{
    color: $color-white;
}

.fill-white{
    fill: $color-white;
}

.bg-white{
    background-color: $color-white;
}

.color-black{
    color: $color-black;
}

.bg-black{
    background-color: $color-black;
}