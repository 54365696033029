$color-white: white;
$color-light-grey: #f4f4f4;
$color-middle-grey: #e0e0e0;
$color-grey: #7f7f7f;
$color-dark-grey: #111111;
$color-less-dark-grey: #222222;
$color-black: #050505;
$color-primary: #fce018;
$color-error: #cf1d1d;
$color-success: #00c554;
$color-warning: #ffb700;

$primary-font: "Flama";
$title-font: "Merriweather", serif;
