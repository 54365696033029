.form {
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .form-row {
    display: grid;
    grid-gap: 1rem;
    margin: 0;

    &:last-of-type {
      .form-item {
        margin: 0;
      }
    }

    &.cols-2 {
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.cols-3 {
      @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &.cols-4 {
      @media (min-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  .form-item {
    width: 100%;
    margin-bottom: 2rem;
    position: relative;

    label {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
      display: flex;
    }

    input,
    textarea,
    .input,
    .textarea {
      width: 100%;
      box-sizing: border-box;
      border: none;
      background-color: #222222;
      box-shadow: none;
      padding: 0.75rem;
      font-size: 1.2rem;
      font-family: $primary-font;
      outline: none;
      color: $color-white;
      height: auto;
      @include transition();

      &:focus {
        background-color: $color-white;
        color: $color-black;
      }
    }
    textarea {
      resize: none;
      min-height: 300px;
    }

    .file-input {
      background-color: #222222;
      padding: 0.75rem;
      font-size: 1.2rem;
      color: #999999;
      cursor: pointer;
    }
    .file-preview {
      background-color: #222222;
      padding: 0.75rem;
      font-size: 1.2rem;
      color: $color-white;
      display: flex;

      .file-remove {
        margin-left: auto;
        padding-left: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .form-error {
    font-size: 0.9rem;
    color: $color-error;
    margin-top: 0.25rem;
  }

  .readOnly {
    padding: 0.75rem 0;
    border-bottom: 1px solid #e0e0e0;
    color: $color-black;
    word-break: break-word;
  }

  .button {
    background-color: $color-white;
    color: $color-black;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 700;

    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }
}
